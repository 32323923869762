import { NextIntlProvider } from 'next-intl';
import { AppProps } from 'next/app';
import React from 'react';
import "../styles/globals.css";
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';

export default function App({Component, pageProps}: AppProps) {
  return (
      <NextIntlProvider messages={pageProps.messages}>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </NextIntlProvider>
  );
}
