import { DefaultTheme } from 'styled-components'
import { createTheme } from 'styled-breakpoints';

export const bpTheme = createTheme({
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  });

  
const defaultTheme: DefaultTheme = {
    borderRadius: '20px',
    palette: {
      common: {
        orange: "#FFC580",
        black: "#000",
        white: "#fff",
        purple: "#5A5AFF",
      },
      primary: {
        main: "#000",
        contrastText: '#fff'
      },
      secondary: {
        main: '#000',
        contrastText: '#ffffff'
      }
    }
};

export default {...defaultTheme, ...bpTheme};